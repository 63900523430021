export const errorHandler = (errors: any) => {
  if (!errors) return [];

  const _errors =
    errors?.response?.data?.errors || errors?.data?.errors || errors?.errors || errors || {};

  if (typeof errors?.response?.data === 'string') {
    return [
      {
        key: 'base',
        code: 'unexpected',
        message: errors?.response?.code === 400 ? errors?.response?.data : 'ERROR: Fix me',
      },
    ];
  }

  return Object.keys(_errors).map((key) => ({
    code: _errors?.[key]?.[0]?.error,
    message: _errors?.[key]?.[0]?.message || _errors?.[key]?.[0],
    key,
  }));
};
